import React from 'react';
import { FrontCommonMessages } from '../appCommonTranslations';
import { getProfessionMessage } from '../appMessageDefinitions';
import useProfessionFirstImages from '../queries/useProfessionFirstImages';
import { AppGatsbyImage, AppGatsbyImageFluid } from '../primitives/appImage';
import { ApplyMountEffect, cardRevealEffect } from '../helpers/reveals';
import { forceImageMaxWidth } from '../appGatsbyImageUtils';
import {
  AppInternalLink,
  AppInternalPath,
  AppInternalPaths,
} from '../primitives/appLink';
import { ShCurrency } from '@shoootin/config';
import { H4, H5 } from '../primitives/appTitle';
import {
  ShIntlMessageDefinition,
  ShText,
  useShIntlLocale,
} from '@shoootin/translations';
import classnames from 'classnames';
import { ProfessionPrice } from '../appConstants';
import { ShPrice } from '@shoootin/components-web';

const CoverProfessionPrice = ({
  image,
  target,
  title,
  price,
  label,
}: {
  image: AppGatsbyImageFluid;
  target: AppInternalPath;
  title: ShIntlMessageDefinition;
  label: ShIntlMessageDefinition;
  price?: {
    amount: number;
    currency: ShCurrency;
  };
}) => {
  return (
    <AppInternalLink to={target}>
      <div className="cover-wrapper">
        <div className={classnames('cover', 'price')}>
          <div className="cover__link" />
          <AppGatsbyImage
            className="cover__image"
            fluid={image}
            style={{ position: 'absolute' }}
          />
          <div className="cover__legend__metier">
            <H5>
              <ShText message={label} />
            </H5>
            {price && (
              <H4>
                <ShText
                  message={title}
                  values={{
                    price: (
                      <ShPrice price={price.amount} currency={price.currency} />
                    ),
                  }}
                />
              </H4>
            )}
          </div>
        </div>
      </div>
    </AppInternalLink>
  );
};

const CoverProfessionPrices = ({
  professionPrices,
}: {
  professionPrices: ProfessionPrice[];
}) => {
  const locale = useShIntlLocale();
  const professionFirstImages = useProfessionFirstImages(locale);
  return (
    <div className="cover-prices">
      {professionPrices.map(({ profession, price }, i) => (
        <ApplyMountEffect
          key={profession}
          index={i}
          effect={(el, index) =>
            cardRevealEffect(el, index, { viewFactor: 0, delay: 0 })
          }
        >
          {(ref) => {
            return (
              <div ref={ref} key={i} className="cover-price">
                <CoverProfessionPrice
                  image={forceImageMaxWidth(
                    professionFirstImages[profession],
                    200,
                  )}
                  target={AppInternalPaths.profession(profession)}
                  title={FrontCommonMessages.priceFrom}
                  label={getProfessionMessage(profession, 'short')}
                  price={price}
                />
              </div>
            );
          }}
        </ApplyMountEffect>
      ))}
    </div>
  );
};

export default CoverProfessionPrices;
