import React, { useMemo } from 'react';
import {
  PrestationName,
  ProfessionPrice,
  ProfessionShootingType,
} from '../../appConstants';
import { useAsync } from 'react-async-hook';
import { professionOffersByService } from '../../appAPI';
import { reportError } from '../../appAnalytics';
import { useFrontCountry } from '../../layout/frontContext';
import { ShShootingType, ShCurrency } from '@shoootin/config';

const useServicePricesLoaders = (
  service: PrestationName,
):
  | {
      byShootingType: Record<ShShootingType, number>;
      currency: ShCurrency;
    }
  | undefined => {
  const country = useFrontCountry();
  const offersAsync = useAsync(
    () => professionOffersByService(country, service),
    [country, service],
    {
      onSuccess: (result) =>
        console.debug('useServicePricesLoaders success', result),
      onError: reportError,
    },
  );

  if (!offersAsync.result) {
    return;
  }
  console.debug('useServicePricesLoaders', offersAsync.result);

  return {
    byShootingType: offersAsync.result.offers,
    currency: offersAsync.result.currency,
  };
};

// This loads the prices remotely
// The price will be undefined until loaded
export const useServiceProfessionPrices = (
  service: PrestationName,
  professions: ProfessionShootingType[],
): ProfessionPrice[] => {
  const servicePrices = useServicePricesLoaders(service);
  return useMemo(() => {
    return professions.map((profession) => {
      return {
        profession: profession,
        price:
          servicePrices && servicePrices.byShootingType[profession]
            ? {
                amount: servicePrices.byShootingType[profession],
                currency: servicePrices.currency,
              }
            : undefined,
      };
    });
  }, [servicePrices]);
};
